/*
|--------------------------------------------------------------------------
| JQTREE
|--------------------------------------------------------------------------
*/
.tree-cont {
  padding: 1px 0 0;
}

.block-style ul.jqtree-tree {
  margin-left: 0;
}

.block-style ul.jqtree-tree ul.jqtree_common {
  margin-left: 2em;
}

.block-style ul.jqtree-tree .jqtree-element {
  padding: 8px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
}

.block-style ul.jqtree-tree .jqtree-element:hover {
  padding: 8px;
  margin-bottom: 8px;
  background-color: #efefef;
}

.block-style ul.jqtree-tree .jqtree-moving .jqtree-element {
  background-color: #fff6d6;
}

.block-style ul.jqtree-tree .jqtree-element .jqtree-title {
  margin-left: 0;
}

.block-style ul.jqtree-tree li.jqtree-selected > .jqtree-element,
.block-style ul.jqtree-tree li.jqtree-selected > .jqtree-element:hover {
  background: #cccccc;
  text-shadow: none;
}

.jqtree-element .actions .ui.button {
  padding: .4em .4em;
  font-weight: 400;
}

.jqtree-element .actions i.icon {
  margin: 0 !important;
}

.jqtree-element .actions {
  float: right;
  margin-top: -2px;
  display: inline-block;
}

/*
|--------------------------------------------------------------------------
| PICKADATE
|--------------------------------------------------------------------------
*/
.ui .picker select {
  width: 35%;
  height: 2em;
  display: inline-block;
}

/*
|--------------------------------------------------------------------------
| MAGNIFIC POPUP
|--------------------------------------------------------------------------
*/
.mfp-bg {
  z-index: 10011;
}

.mfp-wrap {
  z-index: 10012;
}

.mfp-iframe-holder .mfp-content {
  max-width: 90%;
  max-height: 90%;
}

/*
|--------------------------------------------------------------------------
| SORTABLE
|--------------------------------------------------------------------------
*/
.sortable-ghost {
  opacity: 0.5;
  background-color: #f9f9f9;
}

/*
|--------------------------------------------------------------------------
| MAIN
|--------------------------------------------------------------------------
*/
#content {
  padding-top: 70px;
  padding-bottom: 30px;
}

/*
|--------------------------------------------------------------------------
| RESET
|--------------------------------------------------------------------------
*/
table .ui.button,
.dd-item .ui.button {
  padding: .4em .4em;
  font-weight: 400;
}

table .ui.button i.icon,
.dd-item .ui.button i.icon {
  margin: 0 !important;
}

.top.menu .item.icon-only .icon {
  margin: 0;
  font-size: 22px;
}

#content > .ui.menu:first-child {
  margin-top: 1rem;
}

code {
  font-size: 90%;
  color: #c7254e;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #f9f2f4;
}

/*
|--------------------------------------------------------------------------
| UTILITY
|--------------------------------------------------------------------------
*/
.inline.form {
  top: -1px;
  display: inline-block;
}

.stacked.ui.button {
  margin-bottom: 10px;
}

table td.empty {
  color: #bbbbbb;
  font-size: 120%;
}

.modal-content {
  padding: 0 2%;
}

.modal-content.with-menu {
  padding-top: 50px;
  padding-bottom: 25px;
}

.ui.menu .section-title {
  text-transform: uppercase;
}

/*
|--------------------------------------------------------------------------
| AUTH
|--------------------------------------------------------------------------
*/
body#auth {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eee;
}

body#auth .form {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

body#auth .form .header {
  padding-bottom: 10px;
}

body#auth .buttons {
  padding-top: 10px;
}

/*
|--------------------------------------------------------------------------
| THEMES
|--------------------------------------------------------------------------
*/
#themes_cont .card {
  width: 264px;
}

/*
|--------------------------------------------------------------------------
| GALLERY
|--------------------------------------------------------------------------
*/
.gallery-item {
  width: 80px;
  height: 80px;
  margin: 4px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  border: 1px solid #cccccc;
  background-color: #efefef;
}

.gallery-item :hover {
  cursor: move;
}

.gallery-item img {
  height: 80px;
}

.gallery-item .image {
  position: relative;
}

.gallery-item .image .remove-btn {
  top: 0;
  right: 0;
  width: 20px;
  color: #ffffff;
  height: 20px;
  margin: 0;
  position: absolute;
  background: #DB2828;
}

.gallery-item .image .remove-btn :hover {
  cursor: pointer;
}

.gallery-item .image .remove-btn .icon {
  margin: 0;
}

/*
|--------------------------------------------------------------------------
| PAGES
|--------------------------------------------------------------------------
*/
.page-link {
  margin: 5px 0 25px;
}

/*# sourceMappingURL=app.css.map */
